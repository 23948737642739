import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import backgroundImage from "../assets/img/3.png"; // Import the background image

console.log("Background Image:", backgroundImage);

export const Projects = () => {

  const projects = [
    { 
      title: "Expert Consultation & Professional Assistance", 
      description: "For health concerns requiring professional guidance, the system connects users with certified professionals, such as doctors or personal trainers. This unique freelancer-like platform allows professionals to authenticate their licenses, and users can book consultations, where health experts provide customized advice. The assistant gathers health data and generates reports for professionals to review before virtual consultations, ensuring a detailed understanding of the user’s health journey." 
      },   
      { 
      title: "Personalized Meal & Workout Plans", 
      description: "Our AI health assistant tailors meal plans and workout routines based on individual preferences, lifestyle, and health goals. The assistant asks targeted questions to understand user needs and continuously refines suggestions based on feedback and data." 
      },  
      { 
      title: "Comprehensive Health Survey", 
      description: "To provide tailored recommendations, the app includes a comprehensive survey to gather key information: Lifestyle habits (diet, exercise, smoking, etc.), current health status and medications, past health history (surgeries, mental health conditions, etc.), family health history and epigenetic information... The survey helps the assistant better understand user health profiles, ensuring more accurate suggestions and personalized care." 
      },         
      { 
      title: "Collaboration with Health Professionals for LLM Fine-Tuning", 
      description: "We actively collaborate with health professionals to fine-tune our LLMs. By incorporating their expert knowledge and insights, we continuously improve the accuracy and effectiveness of the AI's health recommendations, ensuring that the models evolve to better serve users’ specific needs. This ongoing collaboration helps us deliver precise, professional-level advice tailored to individual cases." 
      },      
      { 
      title: "Specialized Models for Each User", 
      description: "Our health assistant is designed to adapt to the specific needs of each user, personalizing its advice based on their unique medical history, preferences, and lifestyle. This ensures that every user gets tailored health recommendations that are relevant to their individual circumstances, making the experience more meaningful and effective." 
      }, 
      { 
      title: "Professional Earnings and Verified Consultations", 
      description: "Health professionals can authenticate their licenses on the platform, offering their expertise to users while earning from consultations. Professionals can prescribe medications through the platform, and both users and professionals benefit from remote consultations, saving time and effort while maintaining high-quality care." 
      },
      { 
      title: "Weekly Health Reports", 
      description: "Using a combination of API data and the deep learning model, the assistant analyzes photos of meals to calculate macro and micronutrient intake. The system generates detailed weekly reports that track progress, offering users and health professionals insights into the user’s overall health, physical activity, and nutritional balance." 
      }, 
      { 
      title: "Seamless Communication", 
      description: "Through integration with communication platforms like Zoom, users can have virtual consultations with professionals who already have a comprehensive understanding of their health conditions, thanks to the assistant’s data aggregation. This ensures that consultations are efficient and personalized." 
      },
    ];

  return (
    <section className="project" id="projects" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/2.png)`}}>
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Key Features</h2>
                  <p>Explore our innovative health and wellness project's features designed to provide personalized care and expert guidance.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="0">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      {projects.map((project, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={index.toString()} className="custom-nav-link">
                            <span>{project.title}</span>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      {projects.map((project, index) => (
                        <Tab.Pane eventKey={index.toString()} key={index}>
                          <div className="project-description">
                            <p>{project.description}</p>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>}
              </TrackVisibility>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

// Add this style block at the end of the file
const styles = `
  <style>
    .custom-nav-link {
      border-radius: 20px;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    .custom-nav-link:hover,
    .custom-nav-link:focus,
    .custom-nav-link.active {
      background-color: #007bff;
      color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    .custom-nav-link:active {
      transform: scale(0.95);
    }
  </style>
`;

export default () => (
  <>
    <Projects />
    <div dangerouslySetInnerHTML={{ __html: styles }} />
  </>
);

import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { Row, Col, Container } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import userImg from "../assets/img/user.jpeg";
import profImg from "../assets/img/prof.jpeg";

export const Benefits = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const benefit = [
    {
      name: "Benefits For Users",
      description: [
        { title: "Convenience", content: "Personalized health care from the comfort of your home" },
        { title: "Accurate Advice", content: "Feedback based on medical history, lifestyle, and professional insights" },
        { title: "Tailored Plans", content: "Meal and workout suggestions that evolve with your needs" },
        { title: "Specialized Care", content: "The assistant's model is adapted specifically to your needs and health properties" },
        { title: "Access to Experts", content: "Easy connection to professionals for advanced support" }
      ],
      imageUrl: userImg
    },
    {
      name: "Benefits For Professionals",
      description: [
        { title: "Flexible Earnings", content: "Licensed health professionals can offer their services and consultations" },
        { title: "Remote Consultations", content: "Efficient, remote consultations with detailed user health data" },
        { title: "Comprehensive Client Data", content: "Access to users' health history and reports for informed consultations" },
        { title: "Improved LLMs", content: "Collaborate to fine-tune the AI models, ensuring better, more accurate advice for users" },
        { title: "Prescription Capabilities", content: "Doctors can provide prescriptions based on detailed user data" }
      ],
      imageUrl: profImg
    }
  ];

  return (
    <section className="benefits" id="benefits">
      <Container>
      <div className="skill-bx wow zoomIn">
        <Row>
          <Col>
            <h1>Benefits</h1>
            <p>This platform bridges the gap between user-driven AI health assistance and expert medical consultations, while actively incorporating professional expertise and tailoring the models to individual users’ needs. The result is a comprehensive, efficient, and personalized healthcare experience for all.</p>
          </Col>
        </Row>
        <Row>
          {benefit.map((member, index) => (
            <Col lg={6} md={6} key={index}>
              <div className="benefits-box">
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                {member.description.map((item, i) => (
                  <p key={i}>
                    <strong>{item.title}:</strong> {item.content}
                  </p>
                ))}
              </div>
            </Col>
          ))}
        </Row>
        </div>
      </Container>
    </section>
  )
}

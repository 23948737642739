import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const features = [
    { title: "Expert Consultation & Professional Assistance", description: "For health concerns requiring professional guidance, the system connects users with certified professionals, such as doctors or personal trainers. This unique freelancer-like platform allows professionals to authenticate their licenses, and users can book consultations, where health experts provide customized advice. The assistant gathers health data and generates reports for professionals to review before virtual consultations, ensuring a detailed understanding of the user’s health journey." },
    { title: "Personalized Meal & Workout Plans", description: "Our AI health assistant tailors meal plans and workout routines based on individual preferences, lifestyle, and health goals. The assistant asks targeted questions to understand user needs and continuously refines suggestions based on feedback and data." },
    { title: "Comprehensive Health Survey", description: "To provide tailored recommendations, the app includes a comprehensive survey to gather key information: Lifestyle habits (diet, exercise, smoking, etc.), current health status and medications, past health history (surgeries, mental health conditions, etc.), family health history and epigenetic information... The survey helps the assistant better understand user health profiles, ensuring more accurate suggestions and personalized care." },
    { title: "Collaboration with Health Professionals for LLM Fine-Tuning", description: "We actively collaborate with health professionals to fine-tune our LLMs. By incorporating their expert knowledge and insights, we continuously improve the accuracy and effectiveness of the AI's health recommendations, ensuring that the models evolve to better serve users’ specific needs. This ongoing collaboration helps us deliver precise, professional-level advice tailored to individual cases." },
    { title: "Specialized Models for Each User", description: "Our health assistant is designed to adapt to the specific needs of each user, personalizing its advice based on their unique medical history, preferences, and lifestyle. This ensures that every user gets tailored health recommendations that are relevant to their individual circumstances, making the experience more meaningful and effective." },
    { title: "Professional Earnings and Verified Consultations", description: "Health professionals can authenticate their licenses on the platform, offering their expertise to users while earning from consultations. Professionals can prescribe medications through the platform, and both users and professionals benefit from remote consultations, saving time and effort while maintaining high-quality care." },
    { title: "Weekly Health Reports", description: "Using a combination of API data and the deep learning model, the assistant analyzes photos of meals to calculate macro and micronutrient intake. The system generates detailed weekly reports that track progress, offering users and health professionals insights into the user’s overall health, physical activity, and nutritional balance." },
    { title: "Seamless Communication", description: "Through integration with communication platforms like Zoom, users can have virtual consultations with professionals who already have a comprehensive understanding of their health conditions, thanks to the assistant’s data aggregation. This ensures that consultations are efficient and personalized." },
  
  ];

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>DrMedkit</h2>
                        <p>Our cutting-edge AI-powered Health Assistant is designed to provide personalized meal plans, workout routines, and a comprehensive health guide for users. The assistant goes beyond general advice, asking relevant questions to better understand the user's lifestyle and health needs. For more complex health issues, the system connects users with verified health professionals through a freelancer-like platform, ensuring expert guidance. The platform integrates API connections like E-Nabız to gather users' health data, including hospital visits, prescriptions, medical reports, and more, delivering precise feedback and personalized recommendations based on medical history and lifestyle factors.</p>
                        <div className="feature-grid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
